import React, {Fragment}    from "react";
import PropTypes from "prop-types";
import {legacy}  from "/app/js/legacy.js";

export const Contact = props => {
    const element = props.contact;
    const student = props.contact.student;

    if (!element) {
        return (
            <div className="row">
                <div className="col text-center">
                    <h4 className={"text-danger"}>Контакт не установлен!</h4>
                </div>
            </div>
        );
    }

    const onEditButtonClick = (contactId) => {
        legacy.studentEditContactsForm(contactId);
    };

    return (

        <Fragment>

            <div className="row">
                <div className="col-8">
                    <h5>{element.fio}</h5>
                </div>
                <div className="col text-end">
                    <a className="text-success me-2"
                       href={"#"}
                       onClick={() => onEditButtonClick(element.ID)}
                       data-contact-id={element.ID}>
                        <i className="fas fa-pencil-alt me-2"></i>
                    </a>
                    <a className="text-danger" href={`/contacts/${element.ID}/delete/`}
                       data-action="delete"><i className="fas fa-trash me-2"></i></a>
                </div>
            </div>

            <div className="mb-2">
                {element.phone ?
                    <div><i className="fas fa-phone-alt text-primary me-2"></i> {element.phone}</div>
                    :
                    <div><i className="fas fa-phone-alt text-danger me-2"></i> Телефон не указан</div>
                }
            </div>

            <div className="mb-2">
                {element.addr ?
                    <div><i className="far fa-address-card text-primary me-2"></i> {element.addr}</div>
                    :
                    <div><i className="far fa-address-card text-danger me-2"></i> Адрес не указан</div>
                }
            </div>

            <div className="mb-2">
                {element.document ?
                    <div>
                        <i className="far fa-id-badge text-primary me-2"></i>{element.documentDisplay}
                    </div>
                    :
                    <div><i className="far fa-address-card text-danger me-2"></i>Документ не добавлен</div>
                }
            </div>

            {element.isResponsible ?
                <div className="mb-2 text-success">
                    <i className="fas fa-check me-2"></i>Ответственный за обучение
                </div>
                :
                ""
            }

            {student ?
                <div className="mb-2">
                    <i className="fas fa-user-graduate me-2"></i>Слушатель: {student.fio}
                </div>
                :
                ""
            }

        </Fragment>
    );
};

Contact.propTypes = {
    showStudent: PropTypes.bool,
    contact: PropTypes.object,
};
