import React, {Fragment} from "react";
import PropTypes         from "prop-types";
import {StudentLinkView} from "./StudentLinkView";

export const StudentSectionView = props => {
    const element = props.element;
    const person = element.person;
    return (
        <Fragment>
            <StudentLinkView element={props.element}/>
        </Fragment>
    );
};

StudentSectionView.propTypes = {
    element: PropTypes.object,
};
