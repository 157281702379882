import React, {Fragment}    from "react";
import PropTypes            from "prop-types";
import {legacy}             from "/app/js/legacy.js";
import {StudentSectionView} from "./StudentSectionView";

export const ContractView = props => {
    const element = props.element;

    if (!element) {
        return (
            <div className="row">
                <div className="col text-center">
                    <h4 className={"text-danger"}>Контракт не установлен!</h4>
                </div>
            </div>
        );
    }

    return (
        <div className={"card shadow-lg programs-card"}>
            <div className="card-body">
                <div className={"row mb-3"}>
                    <div className={"col"}>
                        <h3 className="card-title">
                            <i className="fas fa-file-contract me-2 text-primary"></i>Договор
                            № {element.number} (ID {element.ID})
                        </h3>
                    </div>
                    <div className={"col text-end"}>
                        <a className={"btn btn-success btn-sm me-2"}
                           role={"button"}
                           href={`/contracts/${element.ID}/edit/`}>
                            <i className="fas fa-pencil-alt me-2"></i>
                            Изменить
                        </a>
                        <a className={"btn btn-primary btn-sm me-2"}
                           role={"button"}
                           href={`/contracts/${element.ID}/print/`}>
                            <i className="fas fa-print me-2"></i>
                            Печать
                        </a>
                        <a className={"btn btn-primary btn-sm"}
                           role={"button"}
                           href={`/contracts/add/`}>
                            <i className="fas fa-plus me-2"></i>
                            Добавить
                        </a>
                    </div>
                </div>

                <div className={"row mb-3"}>
                    <div className={"col"}>
                        <div className={"card shadow-sm h-100"}>
                            <div className="card-body text-center">
                                <h4 className={"card-title"}>
                                    <i className="fas fa-user me-2 text-primary"></i>Слушатель:
                                </h4>
                                <StudentSectionView element={element?.student}/>
                            </div>
                        </div>
                    </div>
                    <div className={"col-4"}>
                        <div className={"card shadow-sm h-100"}>
                            <div className="card-body text-center">
                                <h4 className={"card-title"}>
                                    <i className="fas fa-graduation-cap me-2 text-primary"></i>Программа обучения:
                                </h4>
                                {element.course ?
                                    <div>
                                        {element.program.shortName ?
                                            <h5>{element.program.shortName}</h5>
                                            :
                                            "не указана"
                                        }
                                        {element.program.shortName !== element.program.name ?
                                            <small className={"text-muted"}>{element.program.name}</small>
                                            :
                                            ""
                                        }
                                    </div>
                                    :
                                    "не указана"
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"row mb-3"}>
                    <div className={"col"}>
                        <div className={"card shadow-sm h-100"}>
                            <div className="card-body text-center">
                                <h4 className={"card-title"}>
                                    <i className="far fa-calendar-alt me-2 text-primary"></i>Период
                                </h4>
                                С {element.date_from ? element.date_from : "не указано"} по {element.date_to ? element.date_to : "не указано"}
                            </div>
                        </div>
                    </div>
                    <div className={"col"}>
                        <div className={"card shadow-sm h-100"}>
                            <div className="card-body text-center">
                                <h4 className={"card-title"}>
                                    <i className="far fa-clock me-2 text-primary"></i>Часов
                                </h4>
                                <p>(всего/месяц)</p>
                                {element.thours} / {element.mhours}
                            </div>
                        </div>
                    </div>
                    <div className={"col"}>
                        <div className={"card shadow-sm h-100"}>
                            <div className="card-body text-center">
                                <h4 className={"card-title"}>
                                    <i className="fas fa-wallet me-2 text-primary"></i>Сумма договора
                                </h4>
                                {element.costDisplay}
                            </div>
                        </div>
                    </div>
                    <div className={"col"}>
                        <div className={"card shadow-sm h-100"}>
                            <div className="card-body text-center">
                                <h4 className={"card-title"}>
                                    <i className="fas fa-percentage me-2 text-primary"></i>Скидка:
                                </h4>
                                {element.offer + "%" || "не предоставлена"}
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"row mb-3"}>
                    <div className={"col"}>
                        <div className={"card shadow-sm h-100"}>
                            <div className="card-body text-center">
                                {element.cID && element.contacts.length > 0 ?
                                    <Fragment>
                                        <h4 className={"card-title"}>
                                            <i className="far fa-address-card me-2 text-primary"></i>Контакты по
                                            договору:
                                        </h4>
                                        <div className={"row mb-3"}>
                                            {element.contacts.map((contact, index) => (
                                                    <div key={index} className={"col"}>
                                                        <div className={"card shadow-sm h-100"}>
                                                            <div className="card-body">
                                                                <div>
                                                                    {contact.fio}
                                                                    <a className={"text-success ms-3"}
                                                                       role={"button"}
                                                                       onClick={() => {
                                                                           legacy.studentEditContactsForm(contact.ID);
                                                                       }}
                                                                       href={`#`}>
                                                                        <i className="fas fa-pencil-alt"></i>
                                                                    </a>
                                                                </div>
                                                                <div>{contact.phone}</div>
                                                                <div>{contact.email}</div>
                                                                <div>{contact.addr}</div>
                                                                <div>
                                                                    {contact.isResponsible ?
                                                                        <span className="text-success">ответственный за обучение</span>
                                                                        :
                                                                        ""
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ),
                                            )}
                                        </div>
                                    </Fragment>
                                    : "не указаны"}
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"row"}>
                    <div className={"col"}>
                        <div className={"card shadow-sm h-100"}>
                            <div className="card-body text-center">
                                <h4 className={"card-title"}>
                                    <i className="fas fa-ad me-2 text-primary"></i>Источник информации:
                                </h4>
                                {element.adver_id ? element.adver.name : "не указан"}
                            </div>
                        </div>
                    </div>
                    <div className={"col"}>
                        <div className={"card shadow-sm h-100"}>
                            <div className="card-body text-center">
                                <h4 className={"card-title"}>
                                    <i className="fas fa-certificate me-2 text-primary"></i>Сертификат ПФДО:
                                </h4>
                                {element.certificate ?
                                    <Fragment>
                                        <p>Номер
                                            сертификата: {element.certificate.number ? element.certificate.number : "не указан"}</p>
                                        <p>Сумма
                                            сертификата: {element.certificate.costs ? element.certificate.costsDisplay : "не указан"}</p>
                                    </Fragment>
                                    :
                                    "не использовался!"
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

ContractView.propTypes = {
    element: PropTypes.object,
};
