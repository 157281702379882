import { Pages }      from "./Pages";
import ReactDOM       from "react-dom";
import React          from "react";
import {ContractView} from "../components/ContractView";
import {Contact}      from "../components/Contact";



export class ContactsDetail extends Pages {

    initPageHandlers() {
        super.initPageHandlers();
        this.data = window.diaryJsData;
        ReactDOM.render(
            <Contact
                showStudent={true}
                contact={this.data.element}/>,
            document.getElementById('contactsDetail')
        );

    }
}
